const theme = {
  font: {
    primary: `'Prata', serif`,
    secondary: `'Average', serif`,
  },
  font_size: {
    small: 'font-size: 16px; line-height: 26px',
    regular: 'font-size: 20px; line-height: 28px',
    large: 'font-size: 26px; line-height: 36px',
    larger: 'font-size: 32px; line-height: 44px',
    xlarge: 'font-size: 48px; line-height: 56px',
    xxlarge: 'font-size: 72px; line-height: 84px',
    logo: 'font-size: 96px; line-height: 108px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    grey: {
      light: '#D7D7D7',
      dark: '#313131'
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    primary: '#58c8bd',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
